import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


class IndexPage extends React.Component {
  state = {

  }

  render(){
    return (
      <Layout>
        <SEO title="Support - Tenta" description="You can find, support information about Tenta App."/>
        <h1>support</h1>
        <p>Get contact information and support request in this page.</p>
        <p>
        If you had any issues with Tenta App, or if you have any feature requests or bug reports please contact us with the email <a href="mailto:info@dreamoriented.org">info@dreamoriented.org</a> and we will do our best to help you.
        </p>

      </Layout>
    )
  }
}

export default IndexPage
